<template>
  <div class="dropdown">
    <a
      class="initials mb-0"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <ProfileImage />
    </a>
    <div
      class="dropdown-menu dropdown-menu-right has--radius bg--white p-2"
      aria-labelledby="dropdownMenuButton"
    >
      <div class="px-3 py-2">
        <p class="ana_paragaph ana_text-grey mb-0 d-flex align-items-center">
          <span class="text-muted font-weight-bold">
            {{ user.lastName }} {{ user.firstName }}</span
          >
        </p>
        <div class="f-flex small ana_text-dark">
          {{ user.email }}
        </div>
      </div>
      <div class="dropdown-divider mx-3"></div>
      <router-link to="/dash/YourAccount" class="dropdown-item my-2 px-3 py-2">
        Account Settings
      </router-link>
      <router-link
        to="/dash/YourAccount/Usage"
        class="dropdown-item my-2 px-3 py-2"
      >
        Usage Analytics
      </router-link>
      <router-link
        to="/dash/YourLinks/create"
        class="dropdown-item my-2 px-3 py-2 d-flex justify-content-between align-items-center"
      >
        Create New Link <i class="bi bi-plus-lg"></i>
      </router-link>
      <div class="dropdown-divider mx-3"></div>
      <a
        class="dropdown-item text-danger my-2 px-3 py-2 d-flex justify-content-between align-items-center"
        href="javascript:void(0)"
        @click="logout"
        >Logout <i class="bi bi-box-arrow-right"></i>
      </a>
      <div class="dropdown-divider mx-3"></div>
      <div class="px-3 py-2">
        <button
          class="ana-btn ana-btn__fill w-100 ana-btn--small"
          @click="showSoon"
        >
          Go Pro
          <i class="bi bi-lightning-charge-fill text-warning"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProfileImage from "@/components/dash/ProfileImage";
export default {
  name: "UserDropdown",
  components: {
    ProfileImage,
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
  },
  methods: {
    showSoon() {
      this.$toast.info("Coming soon...");
    },
    logout() {
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style></style>
