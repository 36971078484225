<template>
  <div class="image-avatar">
    <img :src="computedImageUrl" alt="" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProfileImage",
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
    computedImageUrl() {
      return this.user.profileImage
        ? this.user.profileImage
        : "https://api.dicebear.com/6.x/shapes/svg?seed=Casper";
    },
  },
};
</script>

<style>
.image-avatar {
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--secondaryColor);
}
.image-avatar img {
  height:100%;
  width: 100%;
  object-fit:cover;
}
</style>
