<template>
  <section>
    <header class="ui-header">
      <div class="container">
        <div class="col-lg-10 mx-auto p-0 p-0">
          <nav class="ui-nav">
            <div class="ui-nav__left">
              <div class="ui-nav__brand">
                <router-link to="/dash/Overview">
                  <img
                    :src="
                      AppTheme === 'dark_theme'
                        ? require('@/assets/img/brand/ana_white.png')
                        : require('@/assets/img/brand/ana.png')
                    "
                    class="brand-logo"
                    alt=""
                  />
                </router-link>
              </div>
              <div class="ui-nav__menu">
                <ul class="ui-nav__menu-list">
                  <li class="ui-nav__menu-item">
                    <router-link
                      to="/dash/Overview"
                      class="ui-nav__menu-link ui-body__text"
                    >
                      Overview
                    </router-link>
                  </li>
                  <li class="ui-nav__menu-item">
                    <router-link
                      to="/dash/YourLinks"
                      class="ui-nav__menu-link ui-body__text"
                    >
                      Links
                    </router-link>
                  </li>
                  <li class="ui-nav__menu-item">
                    <router-link
                      to="/dash/LinkGroups"
                      class="ui-nav__menu-link ui-body__text"
                    >
                      Groups
                    </router-link>
                  </li>
                  <li class="ui-nav__menu-item">
                    <router-link
                      to="/dash/API"
                      class="ui-nav__menu-link ui-body__text"
                    >
                      API
                    </router-link>
                  </li>
                  <li class="ui-nav__menu-item">
                    <router-link
                      to="/dash/YourTeam"
                      class="ui-nav__menu-link ui-body__text"
                    >
                      Team
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="ui-nav__right d-flex align-items-center">
              <div class="mr-3">
                <router-link to="/dash/YourLinks/Search" class="search">
                  <i class="ti-search"></i>
                </router-link>
              </div>
              <div class="notify mr-3">
                <router-link class="notification" to="/dash/Notifications">
                  <i class="ti-bell"> </i>
                </router-link>
              </div>
              <UserDropdown />
              <span class="ui-nav-toggle toggleMenu ml-3" @click="toggleMenu">
                <span class="one"></span>
                <span class="three"></span>
              </span>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <!-- Navbar (mobile) Start -->
    <nav
      class="ui-mobile-nav"
      :class="{
        active: mobileMenuOpen,
      }"
    >
      <div class="menu w-100">
        <div class="menu-body">
          <div class="row">
            <div class="col-6">
              <router-link to="/dash/Overview" class="menu-link">
                Overview
              </router-link>
            </div>
            <div class="col-6">
              <router-link to="/dash/YourLinks" class="menu-link">
                Links
              </router-link>
            </div>
            <div class="col-6">
              <router-link to="/dash/LinkGroups" class="menu-link">
                Groups
              </router-link>
            </div>
            <div class="col-6">
              <router-link to="/dash/API" class="menu-link"> API </router-link>
            </div>
            <div class="col-6">
              <router-link to="/dash/YourTeam" class="menu-link">
                Team
              </router-link>
            </div>
          </div>

          <div class="menu-footer">
            <router-link
              to="/dash/YourLinks/Create"
              class="ana-btn ana-btn__fill w-100"
            >
              Create New Link
            </router-link>
            <a
              href="javascript:void(0)"
              @click="logout"
              to="/dash/YourAcount"
              class="menu-link text-danger mt-5"
            >
              Logout
            </a>
          </div>
        </div>

        <span class="close toggleMenu" @click="toggleMenu">
          <i class="ti-close"></i>
        </span>
      </div>
    </nav>
    <!-- Navbar (mobile) End  -->
  </section>
</template>

<script>
import UserDropdown from "@/components/UserDropdown";
export default {
  name: "Nav",
  components: {
    UserDropdown,
  },
  data() {
    return {
      mobileMenuOpen: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$router.push({
        path: "/",
      });
    },
    toggleMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
  },
  filters: {
    getFirstLetter: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0);
    },
  },
  watch: {
    $route(to, from) {
      this.mobileMenuOpen = false;
    },
  },
};
</script>
<style>
/* Static Navigation */
.ui-header {
  position: fixed;
  width: 100%;
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background: var(--whiteAltWillChange);
  border-bottom: 1px solid var(--anaGrey2);
  z-index: 100;
  transition: 0.5s ease-in-out;
}

.ui-header .dropdown-toggle::after {
  display: none !important;
}

.ui-header .ui-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
}

.ui-header .ui-nav .ui-nav__brand img {
  width: 100px;
}

.ui-header .ui-nav .ui-nav__menu-list {
  display: inline;
  list-style: none;
}

.ui-header .ui-nav .search,
.ui-header .ui-nav .notification {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background: var(--whiteWillChange2);
  color: var(--primaryColor);
  cursor: pointer;
  border-radius: 100%;
  text-decoration: none !important;
}

.ui-header .ui-nav .initials:hover,
.ui-header .ui-nav .initials {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  text-transform: getFirstLetter;
  background: #19bb69;
  color: #fff;
  cursor: pointer;
  border-radius: 100%;
}

.ui-header .ui-nav .ui-nav__left {
  min-height: 50px;
  display: flex;
  align-items: center;
}

.ui-header .ui-nav .ui-nav__menu-list .ui-nav__menu-item {
  display: inline;
}

.ui-header .ui-nav .ui-nav__menu-list .ui-nav__menu-item .ui-nav__menu-link {
  padding: 0.36rem;
  color: var(--primaryColor);
  text-decoration: none !important;
  transition: 0.4s ease-in-out;
  font-size: 14px !important;
  font-weight: 500;
}

.ui-header
  .ui-nav
  .ui-nav__menu-list
  .ui-nav__menu-item
  .ui-nav__menu-link.router-link-active,
.ui-header
  .ui-nav
  .ui-nav__menu-list
  .ui-nav__menu-item
  .ui-nav__menu-link.active,
.ui-header
  .ui-nav
  .ui-nav__menu-list
  .ui-nav__menu-item
  .ui-nav__menu-link:hover {
  color: var(--secondaryColor);
  font-weight: var(--fontMedium);
}
.ui-mobile-nav {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100%;
  background: var(--whiteWillChange2);
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  transition: 0.2s ease-in-out;
  transform: scale(0);
  padding-top: 4rem;
  overflow: auto;
}

.ui-mobile-nav .menu {
  position: relative;
  z-index: 100;
}

.ui-mobile-nav .menu .menu-body {
  padding: 2rem;
  text-align: left;
  max-width: 400px;
  margin: 1rem auto;
}

.ui-mobile-nav .lines {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ui-mobile-nav .lines {
  width: 100%;
}

.ui-mobile-nav .lines img {
  width: 75%;
}

.ui-mobile-nav .menu .menu-body .menu-link {
  display: block;
  margin: 0.8rem 0;
  color: var(--primaryColor);
  font-size: 1rem;
}

.ui-mobile-nav .menu .menu-body .menu-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 50px;
  margin-top: 3rem;
}

.ui-mobile-nav .close {
  position: fixed;
  top: 2rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--primaryColor);
}

.ui-mobile-nav .close img {
  width: 33px;
}

.ui-nav .ui-nav-toggle {
  display: none;
}

.ui-nav .ui-nav-toggle span {
  display: block;
  width: 24px;
  height: 2px;
  border-top: 2px solid #999;
  margin: 0.35rem auto;
}

@media screen and (max-width: 950px) {
  .ui-mobile-nav {
    display: flex;
  }

  .ui-mobile-nav.active {
    transform: scale(1);
  }

  .ui-nav .ui-nav__menu {
    display: none;
  }

  .ui-nav .ui-nav-toggle {
    display: block;
  }
}

.notify .dropdown-menu {
  min-width: 18rem;
}
</style>
