<template>
  <div>
    <Navbar />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import axios from "axios";
export default {
  name: "DashboardIndex",
  components: {
    Navbar,
  },
  async mounted() {
    try {
      const { data } = await axios.get("me");
      this.$store.dispatch("setUser", data.user);
    } catch (error) {
      console.log("An error occurred");
    }
  },
};
</script>

<style></style>
